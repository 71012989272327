// import 'semantic-ui-css/components/accordion.min.css'
// import 'semantic-ui-css/components/ad.min.css'
// import 'semantic-ui-css/components/breadcrumb.min.css'
import 'semantic-ui-css/components/button.min.css'
import 'semantic-ui-css/components/card.min.css'
import 'semantic-ui-css/components/checkbox.min.css'
// import 'semantic-ui-css/components/comment.min.css'
import 'semantic-ui-css/components/container.min.css'
import 'semantic-ui-css/components/dimmer.min.css'
import 'semantic-ui-css/components/divider.min.css'
import 'semantic-ui-css/components/dropdown.min.css'
// import 'semantic-ui-css/components/embed.min.css'
// import 'semantic-ui-css/components/feed.min.css'
// import 'semantic-ui-css/components/flag.min.css'
// import 'semantic-ui-css/components/form.min.css'
import 'semantic-ui-css/components/grid.min.css'
import 'semantic-ui-css/components/header.min.css'
import 'semantic-ui-css/components/icon.min.css'
import 'semantic-ui-css/components/image.min.css'
import 'semantic-ui-css/components/input.min.css'
// import 'semantic-ui-css/components/item.min.css'
import 'semantic-ui-css/components/label.min.css'
import 'semantic-ui-css/components/list.min.css'
// import 'semantic-ui-css/components/loader.min.css'
import 'semantic-ui-css/components/menu.min.css'
import 'semantic-ui-css/components/message.min.css'
import 'semantic-ui-css/components/modal.min.css'
// import 'semantic-ui-css/components/nag.min.css'
// import 'semantic-ui-css/components/placeholder.min.css'
// import 'semantic-ui-css/components/popup.min.css'
import './popup.css'
// import 'semantic-ui-css/components/progress.min.css'
// import 'semantic-ui-css/components/rail.min.css'
// import 'semantic-ui-css/components/rating.min.css'
import 'semantic-ui-css/components/reset.min.css'
// import 'semantic-ui-css/components/reveal.min.css'
// import 'semantic-ui-css/components/search.min.css'
import 'semantic-ui-css/components/segment.min.css'
// import 'semantic-ui-css/components/shape.min.css'
// import 'semantic-ui-css/components/sidebar.min.css'
// import 'semantic-ui-css/components/site.min.css';
// import 'semantic-ui-css/components/statistic.min.css'
// import 'semantic-ui-css/components/step.min.css'
// import 'semantic-ui-css/components/sticky.min.css'
// import 'semantic-ui-css/components/tab.min.css'
import 'semantic-ui-css/components/table.min.css'
import 'semantic-ui-css/components/transition.min.css'
// import 'semantic-ui-css/components/video.min.css'
