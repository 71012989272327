import http from "../utils/http"

export const upload = async (files: []) => {
    const formData = new FormData()
    files.forEach((file: any) => {
        formData.append('files', file)
    })

    return await http.POSTv2(`/v1/label/upload`, {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data: formData
    })
}

export const save = async (items: []) => {
    return await http.POSTv2(`/v1/label/save`, {
        headers: {
            'Content-Type': 'application/json'
        },
        data: JSON.stringify(items)
    })
}


export const search = async (query) => {
    return await http.GETv2(`/v1/label/search?query=${encodeURI(query)}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export const labelsByOrderSerial = async (orderSerial) => {
    return await http.GETv2(`/v1/label/get/${encodeURI(orderSerial)}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export const print = async (labels) => {
    return await http.POSTv2(`/v1/label/print`, {
        headers: {
            'Content-Type': 'application/json'
        },
        data: JSON.stringify(labels)
    })
}

export const printBox = async (labels) => {
    return await http.POSTv2(`/v1/label/printBox`, {
        headers: {
            'Content-Type': 'application/json'
        },
        data: JSON.stringify(labels)
    })
}
