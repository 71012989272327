import ZebraPrintWrapper from 'zebra-browser-print-wrapper'

const ZebraPrint = new ZebraPrintWrapper()

export const getDefaultPrinter = async function () {
  const printer = await ZebraPrint.getDefaultPrinter()
  ZebraPrint.setPrinter(printer)
  return true
}

export const getPrinterStatus = async function () {
  return await ZebraPrint.checkPrinterStatus()
}

export const sendPrintJob = async function (zpl) {
  return await ZebraPrint.print(zpl)
}

export const decodeZPL = async function (zplArr) {
  let rawZPL = ''
  await zplArr.forEach(zplObj => {
    for (const key in zplObj) {
      if (zplObj.hasOwnProperty(key)) {
        rawZPL += atob(zplObj[key])
      }
    }
  });
  return rawZPL
}

