import React from 'react';
import {
    Icon,
    TableRow,
    TableCell,
    TableBody,
    Table
} from 'semantic-ui-react';

export default function AirflowPreview(props) {
    const { selectedFilter, result } = props;
    const isCustomRef = result.customRef.length > 0
    const dateNow = new Date(Date.now()).toLocaleDateString(
        'en-gb',
        {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
        }
    );
    return (<TableRow style={selectedFilter !== '' ? result.filterType !== selectedFilter ? { display: 'none' } : {} : {}}>
        <TableCell colSpan={6}>
            <Table compact basic celled style={{ width: '70%', margin: 'auto', background: '#f4f4f4b8', opacity: result.print ? 1 : 0.3 }}>
                <TableBody>
                    <TableRow>
                        <TableCell>Consignment Ref: {result.consignment}</TableCell>
                        <TableCell rowSpan={1}>{result.filterType}</TableCell>
                        <TableCell rowSpan={isCustomRef ? 1 : 2}><div style={{
                            fontSize: isCustomRef ? '2vw' : '3vw',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Icon style={{ display: 'table' }} name='arrow alternate circle down outline' /></div></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Order Number: {result.order}</TableCell>
                        <TableCell rowSpan={result.customerOnLabel ? 2 : 3}>{result.filterMeta}</TableCell>
                        {isCustomRef ? <TableCell rowSpan={isCustomRef ? 2 : 1}>{result.customRef}</TableCell> : null}
                    </TableRow>
                    <TableRow>
                        <TableCell>MFR Date: {dateNow} </TableCell>
                        {!isCustomRef ? <TableCell rowSpan={2}>{result.orderSerial}-{result.labelSerial}</TableCell> : null}
                    </TableRow>
                    <TableRow>
                        <TableCell>Checked by: <Icon name='clock outline' /></TableCell>
                        {result.customerOnLabel ? <TableCell>{result.customer}</TableCell> : null}
                        {isCustomRef ? <TableCell>{result.orderSerial}-{result.labelSerial}</TableCell> : null}
                    </TableRow>
                </TableBody>
            </Table>
        </TableCell>
    </TableRow>)
}