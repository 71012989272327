const SFOM_ENUMS = {
  LABEL: {
    TYPE: {
      AIRFLOW: 0,
      BOX: 1
    }
  }
}

export default SFOM_ENUMS
