import {
    fetchAuthSession,
    signOut,
    signInWithRedirect
} from 'aws-amplify/auth'

import http from '../utils/http'

export function currentUserToken() {
    return currentAuthenticatedUser().then((user: any) => {
        return user.signInUserSession.idToken.jwtToken
    }).catch(async () => {
        await signInWithRedirect()
    })
}

export function getOrCreateUserAPI() {
    return new Promise(async (resolve, reject) => {
        const token = await currentUserToken();
        return http.GETv2(`/v1/user/get`, {
            headers: {
                accesstoken: token
            }
        }).then((response) => {
            resolve(response)
        }).catch((e) => {
            reject(e)
        })
    })
}

export const currentAuthenticatedUser = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const { tokens }: any = await fetchAuthSession()
            return resolve({
                attributes: {
                    email: tokens.idToken.payload['cognito:username'],
                    sub: tokens.idToken.payload.sub
                },
                signInUserSession: {
                    idToken: {
                        jwtToken: tokens.idToken.toString()
                    }
                }
            })
        } catch (e) {
            return reject(e)
        }
    })
}

export function getCurrentUser() {
    return new Promise((resolve, reject) => {
        currentAuthenticatedUser().then((cogUser: any) => {
            const { email, sub } = cogUser.attributes
            const { jwtToken } = cogUser.signInUserSession.idToken
            getOrCreateUserAPI().then((user: any) => {
                const { gravatarHash, terms, newUser } = user
                resolve({
                    user: {
                        email, sub, jwtToken, gravatarHash, terms, newUser
                    }
                })
            })
        }).catch(async () => {
            await signInWithRedirect()
            reject(new Error('Not signed in'))
        })
    })
}

export function signOutUser() {
    signOut();
}